import React from "react";

import { BillingEventType, Currency } from "@/autoGeneratedGlobalTypes";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { convertToTime } from "@/utils/dateUtils";
import { currencyToString } from "@/utils/globalTypesUtils";

import { PaymentEventProps } from "./types";
import "./styles.scss";

const PaymentEvent = ({ event }: PaymentEventProps) => {
  if (event.session) {
    return (
      <div className="payment-event__card payment-event__card--blue" key={event.id}>
        <div className="payment-event__icon">
          {event.type === BillingEventType.video_session && (
            <Icon type={IconTypeEnum.VideoOn} size={IconSizeEnum.Size24} />
          )}
          {event.type === BillingEventType.phone_session && (
            <Icon type={IconTypeEnum.Call} size={IconSizeEnum.Size24} />
          )}
          {event.type === BillingEventType.chat_session && (
            <Icon type={IconTypeEnum.Chat} size={IconSizeEnum.Size24} />
          )}
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">Консультация</div>
          <div className="payment-event__numbers">
            {convertToTime(event.timestamp)}
            {" "}
            •
            {event.session.sessionDuration}
            {" "}
            мин
          </div>
        </div>
        <div className="payment-event__total">
          {event.session.amount.amount ? `–${event.session.amount.amount}` : 0}
          &nbsp;
          {currencyToString(event.session?.amount.currency)}
        </div>
      </div>
    );
  }

  if (event.payment && event.type === BillingEventType.product) {
    return (
      <div className="payment-event__card payment-event__card--blue" key={event.id}>
        <div className="payment-event__icon">
          <Icon type={IconTypeEnum.Export} size={IconSizeEnum.Size24} />
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">{event.subtype === "gift" ? "Подарок эксперту" : "Оплата услуги"}</div>
          <div className="payment-event__numbers">
            {convertToTime(event.timestamp)}
            {" "}
            мин
          </div>
        </div>
        <div className="payment-event__total">
          {event.payment.amount ? `–${event.payment.amount}` : 0}
          &nbsp;
          {currencyToString(event.payment.currency)}
        </div>
      </div>
    );
  }

  if (event.payment) {
    return (
      <div
        className="payment-event__card payment-event__card--green"
        key={event?.id}
      >
        <div className="payment-event__icon payment-event__icon--rotate90">
          <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size24} />
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">Пополнение</div>
          <div className="payment-event__numbers">{convertToTime(event.timestamp)}</div>
        </div>
        <div className="payment-event__total">
          {`+${Number(event?.payment?.amount)}`}
          &nbsp;
          {currencyToString(event?.payment?.currency)}
        </div>
      </div>
    );
  }

  if (event.bonus
    && (event.type === BillingEventType.bonus_referral || event.type === BillingEventType.bonus_affiliate)) {
    return (
      <div
        className="payment-event__card payment-event__card--green"
        key={event?.id}
      >
        <div className="payment-event__icon ">
          <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size24} />
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">{`Бонус ${event.type === BillingEventType.bonus_referral ? "от" : "за"} друга`}</div>
          <div className="payment-event__numbers">{convertToTime(event.timestamp)}</div>
        </div>
        <div className="payment-event__total payment-event__referral-total">
          {`+${event.bonus?.amount} `}
          бесплатных
        </div>
      </div>
    );
  }

  if (event.bonus) {
    return (
      <div
        className="payment-event__card payment-event__card--yellow"
        key={event?.id}
      >
        <div className="payment-event__icon payment-event__icon--rotate90">
          <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size24} />
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">Бонусы</div>
          <div className="payment-event__numbers">{convertToTime(event.timestamp)}</div>
        </div>
        <div className="payment-event__total">
          {`+${event.bonus?.amount}`}
          &nbsp;
          {currencyToString(Currency.RUB)}
        </div>
      </div>
    );
  }
  if (event.correction) {
    const amount = Number(event?.correction?.amount);
    return (
      <div
        className={`payment-event__card payment-event__card${
          amount > 0 ? "--green" : "--blue"
        }`}
        key={event?.id}
      >
        <div className={`payment-event__icon${amount > 0 ? " payment-event__icon--rotate180" : ""}`}>
          <Icon type={IconTypeEnum.Export} size={IconSizeEnum.Size24} />
        </div>
        <div className="payment-event__info">
          <div className="payment-event__action">Корректировка</div>
          <div className="payment-event__numbers">{convertToTime(event.timestamp)}</div>
        </div>
        <div className="payment-event__total">
          {`${amount > 0 ? "+" : ""}${amount}`}
          &nbsp;
          {currencyToString(event?.correction?.currency!)}
        </div>
      </div>
    );
  }
  return null;
};

export default PaymentEvent;
